html {
  /* width: 100vw;
  height: 100vh;*/
}

body {
  margin: 0;
  font-family: "Poppins", "Comfortaa", "Kanit", -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  /*-webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;*/
}

/* width */
::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

* {
  scrollbar-width: thin;
  scrollbar-height: thin;
  scrollbar-color: #888 #f1f1f1;
}

.label-Bronze {
  color: #cd7f32 !important;
}

.label-Silver {
  color: #c0c0c0;
}

.label-Gold {
  color: #ffd700;
}
