body {
  margin: 0;
  font-family: Poppins, Comfortaa, Kanit, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

* {
  scrollbar-width: thin;
  scrollbar-height: thin;
  scrollbar-color: #888 #f1f1f1;
}

.label-Bronze {
  color: #cd7f32 !important;
}

.label-Silver {
  color: silver;
}

.label-Gold {
  color: gold;
}

/*# sourceMappingURL=index.e4479d20.css.map */
